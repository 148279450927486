import React from 'react';
import { FaInstagram, FaWhatsapp, FaFacebook } from 'react-icons/fa';
import Img from '../img/contact/img.jpg'
// import motion
import {motion} from 'framer-motion'
//import transition
import { transition1 } from '../transitions';

 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"

const InstagramCard = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="bg-white rounded-lg drop-shadow-md p-6 max-w-xs  lg:max-h-[460px] lg:w-[400px] mx-auto">
        <div className="text-center">
      <motion.img
          whileHover={{scale: 1.1}}
          transition={{transition1}}
          src={Img}
          alt="Опис фото"
          className="w-52 h-52 rounded-full mx-auto mb-4 object-cover"
        />
        <h2 className="text-xl font-bold pb-3">{t("contact.cardTitle")}</h2>
        <p className="text-gray-600 pb-4">{t("contact.cardText")}</p>
      </div>
      <div className="flex justify-center items-center mb-4">
        <a className='w-16 h-16 rounded-full bg-instagram flex justify-center items-center transition-transform duration-300 transform hover:scale-110' href="https://www.instagram.com/beauty_dog.il/" target='_blank'>
               <FaInstagram size={28} color="white" />
        </a>
        <a className='w-16 h-16 rounded-full bg-whatsapp flex justify-center items-center mx-4 transition-transform duration-300 transform hover:scale-110' href="https://wa.me/972559560622?" target='_blank'>
               <FaWhatsapp size={28} color="white" />
        </a>
        <a className='w-16 h-16 rounded-full bg-facebook flex justify-center items-center transition-transform duration-300 transform hover:scale-110' href="https://www.facebook.com/profile.php?id=100087976549520" target='_blank'>
               <FaFacebook size={28} color="white" />
        </a>
      </div>
    </div>
  );
};

export default InstagramCard;