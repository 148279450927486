import React, {useState} from 'react';
//import icons
import {IoMdClose} from 'react-icons/io';
import {CgMenuRight} from 'react-icons/cg';
// import link
import { Link } from 'react-router-dom';
// import motion
import { motion } from 'framer-motion';
import {useTranslation } from "react-i18next"


//menu variants
const menuVariants = {
   hidden: {
      x: '100%'
   },
   show: {
      x: 0,
      transition: {
         ease: [0.6, 0.01, -.05, 0.9],
      },
   },
};

const MobileNav = () => {
   const [t, i18n] = useTranslation("global");
   const [currentLanguage, setCurrentLanguage] = useState("ru"); // Додали стан для поточної мови
 
   const handleChangeLanguage = (lang) => {
     i18n.changeLanguage(lang);
     setCurrentLanguage(lang); // Оновлюємо стан поточної мови після зміни мови
   }

   const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className='text-primary xl:hidden'>
    {/* nav open button */}
    <div 
      onClick={()=> setOpenMenu(true)} 
      className='text-3xl text-white/60 cursor-pointer'
      >
      <CgMenuRight />
    </div>
    {/* menu */}
    <motion.div
      variants={menuVariants}
      initial='hidden'
      animate={openMenu ? 'show' : ''}
      className='bg-white shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen z-20'>
      {/* icon */}
      <div onClick={()=> setOpenMenu(false)} className='text-4xl absolute z-30 left-4 top-14 text-primary cursor-pointer'>
         <IoMdClose />
      </div>
      {/* menu list */}
      <ul className='h-full flex flex-col justify-center items-center gap-y-8 text-primary font-primary font-blood text-3xl'>
         <div className='flex mb-10 text-2xl'>
            <button className={`text-black m-2 ${currentLanguage === "ru" ? 'active' : ''}`} onClick={() => handleChangeLanguage("ru")}>Ru</button>
            <span className="my-auto">/</span>
            <button className={`text-black m-2 ${currentLanguage === "en" ? 'active' : ''}`} onClick={() => handleChangeLanguage("en")}>En</button>
         </div>
         <li>
            <Link to='/' onClick={()=> setOpenMenu(false)}>{t("header.home")}</Link>
         </li>
         <li>
            <Link to='/about' onClick={()=> setOpenMenu(false)}>{t("header.about")}</Link>
         </li>
         <li>
            <Link to='/transformation' onClick={()=> setOpenMenu(false)}>{t("header.beforeAfter")}</Link>
         </li>
         <li>
            <Link to='/price' onClick={()=> setOpenMenu(false)}>{t("header.price")}</Link>
         </li>
         <li>
            <Link to='/contact' onClick={()=> setOpenMenu(false)}>{t("header.contact")}</Link>
         </li>
      </ul>
    </motion.div>
    </nav>
  );
};

export default MobileNav;