import React from 'react';
 // import motion
 import { motion } from 'framer-motion';
//variant
import { fadeIn } from '../variants';

 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"

const Price = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className='overflow-x-hidden'>
      <section className='section'>
        <div>
          {/* Background */}
          <div className='relative'>
            <div className='bg-price h-[300px] w-full flex items-center relative bg-no-repeat bg-cover bg-center '></div>
            {/* Text in the top-left corner */}
            <motion.div 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='absolute ml-2 sm:ml-10 md:ml-20 top-40 w-full '>
              <h1 className='h1 text-white/80 lg:ml-6'>{t("price.header")}</h1>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto px-4 py-8">
          <motion.p 
          variants={fadeIn('left', 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className=" mb-4">
          {t("price.description")}
          </motion.p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {/* Послуги та їх ціни */}
            {/* Стрижка */}
            <motion.div 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className="bg-white p-4 shadow-md">
              <h3 className="text-xl font-semibold mb-4">{t("price.services.haircut.title")}</h3>
              <ul className="list-disc pl-6">
                <li>{t("price.services.haircut.type.0")}</li>
                <li>{t("price.services.haircut.type.1")}</li>
                <li>{t("price.services.haircut.type.2")}</li>
                <li>{t("price.services.haircut.type.3")}</li>
                <li>{t("price.services.haircut.type.4")}</li>
                <li>{t("price.services.haircut.type.5")}</li>
                <li>{t("price.services.haircut.type.6")}</li>
                <li>{t("price.services.haircut.type.7")}</li>
              </ul>
            </motion.div>
            <div className='grid  gap-4'>
            {/* Лечение*/}
              <motion.div 
              variants={fadeIn('left', 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{once: false, amount: 0.3}}
              className="bg-white p-4 shadow-md">
                <h3 className="text-xl font-semibold mb-4">{t("price.services.healing.title")}</h3>
                <ul className="list-disc pl-6">
                  <li>{t("price.services.healing.type")}</li>
                </ul>
              </motion.div>

            {/* Додаткові опції */}
              <motion.div 
              variants={fadeIn('right', 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{once: false, amount: 0.3}}className="bg-white p-4 shadow-md" colSpan={2}>
                <h3 className="text-xl font-semibold mb-4">{t("price.services.options.title")}</h3>
                <ul className="list-disc pl-6">
                  <li>{t("price.services.options.type.0")}</li>
                  <li>{t("price.services.options.type.1")}</li>
                </ul>
              </motion.div>
            </div>
          </div>

          {/* Контактна інформація та кнопка для запису */}
          <div className="mt-8 text-center">
            <motion.p
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            >
               {t("price.contactInfo")}
            </motion.p>
            <motion.p 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}className='mb-5'>{t("price.phone")} (+972) 55-956-0622</motion.p>
            <a className='btn-grad' href='https://wa.me/972559560622?' target='_blank'>{t("price.button")}</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Price;
