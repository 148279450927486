import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import global_ru from './translations/ru/global.json'
import global_en from './translations/en/global.json'
import i18next from 'i18next';
import { I18nextProvider, useTranslation } from 'react-i18next'; // Make sure to import I18nextProvider from 'react-i18next'


i18next.init({
   interpolation: { escapeValue: false},
   lng: 'ru',
   resources: {
      ru: {
         global: global_ru
      },
      en: {
         global: global_en
      }
   }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
   </React.StrictMode>
);