import React from 'react'
import BeforeAfterGallery from '../components/BeforeAfterGallery'

 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"
 // import motion
 import { motion } from 'framer-motion';
 //variant
import { fadeIn } from '../variants';


const BeforeAfter = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className='overflow-x-hidden'>
      <section 
      className='section'>
        {/* Background */}
        <div
         className='relative'>
            <div className='bg-beforeafter h-[300px] w-full flex items-center relative bg-no-repeat bg-cover bg-center '></div>
            {/* Text in the top-left corner */}
            <motion.div 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}className='absolute ml-2 sm:ml-10 md:ml-20 top-40 w-full '>
              <h1 className='h1 text-white/80 lg:ml-6'>{t("before/after.header")}</h1>
            </motion.div>
          </div>
      </section>
        <BeforeAfterGallery />
    </div>
  )
}

export default BeforeAfter