import React from 'react';
//import pages
import Home from '../pages/Home';
import About from '../pages/About';
import BeforeAfter from '../pages/BeforeAfter';
import Price from '../pages/Price';
import Contact from '../pages/Contact';



//import routes route & useLocation hook
import { Route, useLocation, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

const AnimRoutes = () => {
   const location = useLocation();
  return (
   <AnimatePresence initial={true} mode='wait'>
      <Routes key={location.pathname} location={location}>
         <Route path='/' element={<Home />} />
         <Route path='/about' element={<About />} />
         <Route path='/transformation' element={<BeforeAfter />} />
         <Route path='/price' element={<Price />} />
         <Route path='/contact' element={<Contact />} />
      </Routes>
  </AnimatePresence>
  );
};

export default AnimRoutes;
