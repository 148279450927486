import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
// import transition
import {transition1} from '../transitions'

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"

// import videos
import Vid1 from '../img/beforeafter/1.MP4';
import Vid2 from '../img/beforeafter/2.MP4';
import Vid3 from '../img/beforeafter/3.MP4';
import Vid4 from '../img/beforeafter/4.MP4';
import Vid5 from '../img/beforeafter/5.MP4';
import Vid6 from '../img/beforeafter/6.MP4';
import Vid7 from '../img/beforeafter/7.MP4';
import Vid8 from '../img/beforeafter/8.MP4';
import Vid9 from '../img/beforeafter/9.MP4';
import Vid10 from '../img/beforeafter/10.MP4';
import Vid11 from '../img/beforeafter/11.MP4';
import Vid12 from '../img/beforeafter/12.MP4';
import Vid13 from '../img/beforeafter/13.MP4';
import Vid14 from '../img/beforeafter/14.MP4';
import Vid15 from '../img/beforeafter/15.MP4';
//import images
import Image1 from '../img/beforeafter/1img.jpg';
import Image2 from '../img/beforeafter/2img.jpg';
import Image3 from '../img/beforeafter/3img.jpg';
import Image4 from '../img/beforeafter/4img.jpg';
import Image5 from '../img/beforeafter/5img.jpg';
import Image6 from '../img/beforeafter/6img.jpg';
import Image7 from '../img/beforeafter/7img.jpg';
import Image8 from '../img/beforeafter/8img.jpg';
import Image9 from '../img/beforeafter/9img.jpg';
import Image10 from '../img/beforeafter/10img.jpg';
import Image11 from '../img/beforeafter/11img.jpg';
import Image12 from '../img/beforeafter/12img.jpg';
import Image13 from '../img/beforeafter/13img.jpg';
import Image14 from '../img/beforeafter/14img.jpg';


const BeforeAfterGallery = () => {
  const [t, i18n] = useTranslation("global");

    const handleSlideChange = (swiper) => {
        const videos = document.querySelectorAll('video');
        videos.forEach((video) => {
          if (swiper.slides[swiper.activeIndex].contains(video)) {
            video.play();
          } else {
            video.pause();
          }
        });
      };
  return (
    <motion.section
    initial={{ opacity: 0, y: '100%'}}
    animate={{opacity: 1, y: 0}}
    exit={{opacity: 0, y: '100%'}}
    transition={transition1}
    className="section pb-20">
      <div className="container mx-auto h-full relative">
        <div className="flex-col lg:flex-row h-full items-center justify-start gap-x-24 text-center pt-10 lg:pt-0 pb-8">
            {/* text */}
         <div className='flex flex-col'>
            <h1 className='h1'>{t("before/after.title")}</h1>
            <p className='m-4'>
            {t("before/after.description")}
            </p>
         </div>
          {/* Videos */}
          <div className="relative flex items-center pt-5 lg:pt-10 hidden lg:block">
            <div id="slider" className="w-full h-full whitespace-nowrap">
              <Swiper
                spaceBetween={1}
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={1}
                centeredSlides={true}
                breakpoints={{
                  550: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                coverflowEffect={{}}
                pagination={{ el: '.swiper-pagination', clickable: 'true' }}

                modules={[EffectCoverflow, Pagination, Navigation]}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                className="relative h-[520px] rounded-xl"
              >
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid1} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid2} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid3} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid4} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid5} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid6} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid7} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid8} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid9} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid10} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid11} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid12} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid13} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid14} type='video/mp4' />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                <video className='rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1' autoPlay muted>
                     <source src={Vid15} type='video/mp4' />
                  </video>
                </SwiperSlide>

                <div className="slider-controller flex relative items-center justify-center  ">
                <div className='hidden'>
                  <div className="swiper-button-prev slider-arrow text-2xl hidden">
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </div>
                  <div className="swiper-button-next slider-arrow text-2xl">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </Swiper>
            </div>
          </div>
          {/* Photos */}
          <div className="relative flex items-center pt-5 lg:pt-10  lg:hidden">
          <div id="slider" className="w-full h-full whitespace-nowrap">
              <Swiper
                spaceBetween={1}
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={1}
                centeredSlides={true}
                breakpoints={{
                  550: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                    initialSlide: 1
                  },
                }}
                coverflowEffect={{}}
                pagination={{ el: '.swiper-pagination', clickable: 'true' }}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                  clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="relative h-[480px] rounded-xl"
              >
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image1}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image2}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image3}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image4}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image5}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image6}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image7}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image8}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image9}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image10}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image11}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image12}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image13}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[480px] w-[270px] inline-block p-1 cursor-pointer"
                    src={Image14}
                    alt=""
                  />
                </SwiperSlide>

                <div className="slider-controller flex relative items-center justify-center bottom-10 ">
                <div className='hidden'>
                  <div className="swiper-button-prev slider-arrow text-2xl hidden">
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </div>
                  <div className="swiper-button-next slider-arrow text-2xl">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </Swiper>
            </div>
          </div>

        </div>
      </div>
    </motion.section>
  );
};

export default BeforeAfterGallery;
