import React, { useState } from 'react';
// import components
import Socials from './Socials';
import MobileNav from './MobileNav';

import {useTranslation } from "react-i18next"

//import link
import { Link } from 'react-router-dom';


const Header = () => {
   const [t, i18n] = useTranslation("global");
   const [currentLanguage, setCurrentLanguage] = useState("ru"); // Додали стан для поточної мови
   const [currentTab, setCurrentTab] = useState("home"); // Додали стан для поточної активної вкладки



 
   const handleChangeLanguage = (lang) => {
     i18n.changeLanguage(lang);
     setCurrentLanguage(lang); // Оновлюємо стан поточної мови після зміни мови
   }

  return (
   <header className='bg-black/80 fixed w-full px-[30px] lg:px-[80px] z-30 h-[90px] lg:h-[80px] flex items-center'>
   <div className="flex flex-col lg:flex-row lg:items-center w-full justify-between">
      {/*Logo*/}
      <Link to={'/'} className='max-w-[300px]'>
      <div className='text-gradient2'>
            <span className=' text-[28px] sm:text-[42px]'>BEAUTY_DOG</span>
         </div>
      </Link>
      {/*nav - initially hidden - show on desktop mode*/}
      <nav className='hidden xl:flex gap-x-12 font-semibold'>
         <Link 
            to={'/'} 
            className='text-[#696c6d] hover:text-white/60 transition'
         >
            {t("header.home")}
         </Link>
         <Link
            to={'/about'}
            className='text-[#696c6d] hover:text-white/60 transition'
         >
            {t("header.about")}
         </Link>
         <Link
            to={'/transformation'}
            className='text-[#696c6d] hover:text-white/60 transition'
         >
            {t("header.beforeAfter")}
         </Link>
         <Link
            to={'/price'}
            className='text-[#696c6d] hover:text-white/60 transition'
         >
            {t("header.price")}
         </Link>
         <Link
            to={'/contact'}
            className='text-[#696c6d] hover:text-white/60 transition'
         >
            {t("header.contact")}
         </Link>
      </nav>
   </div>
      <div className=' pl-10 hidden sm:flex text-xl'>
         <button className={`text-white/60 m-2 hover:text-pink-600 ${currentLanguage === "ru" ? 'active' : ''}`} onClick={() => handleChangeLanguage("ru")}>Ru</button>
        <button className={`text-white/60 m-2 hover:text-pink-600 ${currentLanguage === "en" ? 'active' : ''}`} onClick={() => handleChangeLanguage("en")}>En</button>
      </div>
      {/*Socials*/}
      <div className='ml-8'>
         <Socials />
      </div>
      {/*mobile nav*/}
      <MobileNav />
  </header>
  );
};

export default Header