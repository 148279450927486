import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// motion
import { motion } from 'framer-motion';
//variant
import { fadeIn } from '../variants';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

// import images
import Image1 from '../img/home/1.jpg';
import Image2 from '../img/home/2.jpg';
import Image3 from '../img/home/3.jpg';
import Image4 from '../img/home/4.jpg';
import Image5 from '../img/home/5.jpg';
import Image6 from '../img/home/6.jpg';
import Image7 from '../img/home/7.jpg';
import Image8 from '../img/home/8.jpg';
import Image9 from '../img/home/9.jpg';
import Image10 from '../img/home/10.jpg';
import Image11 from '../img/home/11.jpg';
import Image12 from '../img/home/12.jpg';
import Image13 from '../img/home/13.jpg';
import Image14 from '../img/home/14.jpg';
import Image15 from '../img/home/15.jpg';
import Image16 from '../img/home/16.jpg';
import Image17 from '../img/home/17.jpg';
import Image18 from '../img/home/18.jpg';
import Image19 from '../img/home/19.jpg';
import Image20 from '../img/home/20.jpg';
import Image21 from '../img/home/21.jpg';
import Image22 from '../img/home/22.jpg';
import Image23 from '../img/home/23.jpg';
import Image24 from '../img/home/24.jpg';
 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"

const HomeGallery = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <motion.section className="section pb-20">
      <div className="container mx-auto h-full relative">
        <div className="flex-col lg:flex-row h-full items-center justify-start gap-x-24 text-center pt-10 lg:pt-0">
            {/* text */}
         <motion.div 
         variants={fadeIn('right', 0.3)}
         initial="hidden"
         whileInView={"show"}
         viewport={{once: false, amount: 0.3}}>
            <h1 className='h1 text-gradient'>
            {t("homegallery.welcomeText")}
            </h1>
         </motion.div>
          {/* Photo */}
          <motion.div 
          variants={fadeIn('left', 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className="relative flex items-center pt-8 lg:pt-10">
            <div id="slider" className="w-full h-full whitespace-nowrap">
              <Swiper
                spaceBetween={1}
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={1}
                centeredSlides={true}
                breakpoints={{
                  550: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                    initialSlide: 1
                  },
                }}
                coverflowEffect={{}}
                pagination={{ el: '.swiper-pagination', clickable: 'true' }}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                  clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="relative h-[480px] rounded-xl"
              >
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image24}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image23}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image22}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image21}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image20}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image19}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image18}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image17}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image16}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image15}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image1}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image2}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image3}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image4}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image5}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image6}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image7}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image8}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image9}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image10}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image11}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image12}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image13}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="rounded-3xl object-cover h-[400px] w-[314px] inline-block p-1 cursor-pointer"
                    src={Image14}
                    alt=""
                  />
                </SwiperSlide>

                <div className="slider-controller flex relative items-center justify-center bottom-10 ">
                <div className='hidden'>
                  <div className="swiper-button-prev slider-arrow text-2xl hidden">
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </div>
                  <div className="swiper-button-next slider-arrow text-2xl">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </Swiper>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default HomeGallery;
