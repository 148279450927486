import React from 'react'
import Socials from '../components/Socials'

//import link
import { Link } from 'react-router-dom';
// import logo
import Logo from '../img/footer/logo.png'
 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"
 //import motion
 import {motion} from 'framer-motion';
 //import transition
 import { transition1 } from '../transitions';
 

const Footer = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <motion.footer
     initial={false}
     animate={true}
     exit={{opacity: 0}}
     transition={transition1}
    className="bg-black/80 text-white py-4  ">
      <div className="container mx-auto flex flex-wrap justify-center  lg:justify-between items-center mt-10">
        {/* Логотип і інформація */}
        <div className="w-full  md:w-1/3 mb-4  md:mb-0">
            <div className=''> 
              <Link to={'/'}>
                <img src={Logo} alt="Логотип" className="ml-28 md:ml-20 w-32 h-32" />
              </Link>
                <p className="mt-2 ml-20 md:ml-10 text-white/60 ">{t("footer.location")}</p>
                <hr className=" ml-24 border-gray-600 my-2 w-32 lg:hidden" />
            </div>
        </div>

        {/* Menu */}
        <nav className="w-full md:w-1/3 mb-4 md:mb-0 hidden lg:block">
          <ul className="flex mr-4 flex-col justify-center text-center text-white/40 ">
            <Link 
               to={'/'} 
               className='hover:text-white/70'
            >
               {t("footer.menu.home")}
            </Link>
            <Link
            to={'/about'}
            className='hover:text-white/70'
            >
            {t("footer.menu.about")}
            </Link>
            <Link
            to={'/transformation'}
            className='hover:text-white/70'
            >
            {t("footer.menu.beforeAfter")}
            </Link>
            <Link
            to={'/price'}
            className='hover:text-white/70'
            >
            {t("footer.menu.price")}
            </Link>
            <Link
            to={'/contact'}
            className='hover:text-white/70'
             >
            {t("footer.menu.contact")}
            </Link>
          </ul>
        </nav>

        {/* Графік роботи телефон і т.д. */}
        <div className="w-full md:w-1/3 mb-4 md:mb-0 text-white/60">
            <div className='ml-24 md:ml-20 '>
                <p>
                  {t("footer.schedule.workingHours")}
                </p>
                  <hr className=" border-gray-600 my-2 w-40" />
                <p>
                  {t("footer.schedule.appointmentInfo")}
                </p>
                <hr className=" border-gray-600 my-2 w-40" />
                <p className='pb-5 pt-3'>{t("footer.schedule.phone")} +972 55-956-0622</p>
                <Socials />
            </div>
        </div>
      </div>

      {/* Риска */}
      <hr className=" border-gray-600 my-2" />

      {/* Копірайт */}
      <div className="text-center  text-blue-300">
        <p>© 2023 BEAUTY_DOG</p>
      </div>
      <div className='flex justify-center'>
        <div className="  ">
            <p>{t("footer.developedBy")}</p>
        </div>
        <div className='pl-1 text-red-300'>
            <a href='https://romanzarichanskyi.online/'>RZ</a>
        </div>
      </div>
    </motion.footer>
  )
}

export default Footer