import React from 'react'
 // import images
 import Img from '../img/home/img1.png'
 // import Link
 import { Link } from 'react-router-dom';
 //import motion
 import {motion} from 'framer-motion';
 //import transition
 import { transition1 } from '../transitions';
 import HomeGallery from '../components/HomeGallery';
 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"




const Home = () => {
   const [t, i18n] = useTranslation("global");

  return (
   <div className='overflow-x-hidden'>
    <motion.section 
     initial={{ opacity: 0}}
     animate={{opacity: 1}}
     exit={{opacity: 0}}
     transition={transition1}
     className='section'>
         <div className="container mx-auto h-full relative">
            {/* text & img wrapper */}
            <div className='flex flex-col justify-center'>
               {/* text */}
               <motion.div
                initial={{ opacity: 0, y: '-50%'}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: '-50%'}}
                transition={transition1}
                className='w-full pt-28 pb-14 lg:pt-0 lg:pb-80 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start'>
                  <h1 className='h1 text-gradient'>
                     {t("home.welcomeText1")} <br />
                     {t("home.welcomeText2")}
                  </h1>
                  <p className=' m-3 max-w-sm'>
                     {t("home.description")}
                  </p>
                  <Link to={'/contact'} className='btn-grad mb-[30px] mt-[20px] hidden lg:block'>
                     {t("home.contactButton")}
                  </Link>
               </motion.div>
               {/* image */}
               <motion.div
                initial={{ scale: 0}}
                animate={{scale: 0.75}}
                exit={{scale: 0}}
                transition={transition1} 
                className='flex justify-end max-h-max lg:scale-75'>
                  <div className='relative bottom-6 lg:left-40 lg:bottom-48 overflow-hidden'>
                     <motion.img className=' '
                   
                     src={Img} alt="" />
                     {/* button */}
                     <div className="absolute inset-0 flex flex-col items-center justify-center lg:hidden">
                     <Link to={'/contact'} className='btn mt-[360px]'>{t("home.contactButton")}</Link>
                     </div>
                  </div>
               </motion.div>
            </div>
         </div>
      </motion.section>
      <HomeGallery />
   </div>
  )
}

export default Home;