import React from 'react';
import { BsTelephone } from 'react-icons/bs';
import InstagramCard from './InstagramCard';
 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"
 // motion
import { motion } from 'framer-motion';
//variant
import { fadeIn } from '../variants';

const Contact = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className='overflow-x-hidden'>
      <section className='section'>
        <div>
          {/* Background */}
          <div className='relative'>
            <div className='bg-contact h-[300px] w-full flex items-center relative bg-no-repeat bg-cover bg-center '></div>
            {/* Text in the top-left corner */}
            <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}} 
            className='absolute ml-2 sm:ml-10 md:ml-20 top-40 w-full '>
              <h1 className='h1 text-white/80 lg:ml-6'>{t("contact.header")}</h1>
            </motion.div>
          </div>
        </div>
      </section>
      {/* Contacts */}
      <section className='section pb-10'>
        <div className='container h-auto lg:h-[360px] m-2 sm:ml-10 md:ml-24 flex flex-col md:flex-row'>
          <motion.div
          variants={fadeIn('right', 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}>
            <h1 className='h1 text-black'>BEAUTY_DOG</h1>
            <p1>
            {t("contact.workingHours")} <br/>
            {t("contact.appointmentInfo")}
            </p1>
            <div className='flex items-center mt-4 pb-8'>
              <BsTelephone className='text-pink-800 hover:text-black text-3xl' />
              <a
                className='text-black/70 text-xl hover:text-black pl-3'
                href='tel:785439698'
                target='_self'
              >
                (+972) 55-956-0622
              </a>
            </div>
            <a className='btn-grad ml-5 lg:ml-0' href="https://wa.me/972559560622?" target='_blank'>
            {t("contact.whatsappButton")}
            </a>
          </motion.div>

          <motion.div 
          variants={fadeIn('left', 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='relative mr-4 md:-top-28 lg:ml-44 mt-10'>
            <InstagramCard />
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
