import React, {useEffect} from 'react';
// import components
import Header from './components/Header';
import Footer from'./components/Footer';
import AnimRoutes from './components/AnimRoutes';

// import routes
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
   const { pathname } = useLocation();
 
   useEffect(() => {
     window.scrollTo(0, 0);
   }, [pathname]);
 
   return null;
 };

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <AnimRoutes />
      <Footer />
    </Router>
  )
}

export default App;
