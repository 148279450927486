import React from 'react';
//import icons
import {
   FaInstagram,
   FaFacebook,
   FaWhatsapp,
   FaViber,
} from 'react-icons/fa'

const Socials = () => {
  return (
    <div className='hidden xl:flex'>
      <ul className='flex gap-x-3 text-xl text-white/40'>
         <li>
            <a className='hover:text-white/80' href="https://www.instagram.com/beauty_dog.il/" target='_blank'>
            <FaInstagram />
            </a>
         </li>
         <li>
            <a className='hover:text-white/80' href="https://wa.me/972559560622?" target='_blank'>
               <FaWhatsapp />
            </a>
         </li>
         <li>
            <a className='hover:text-white/80' href="https://www.facebook.com/profile.php?id=100087976549520" target='_blank'>
               <FaFacebook />
            </a>
         </li>
      </ul>
    </div>
  )
}

export default Socials